"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../sass/style.scss");
var validate_1 = require("./validate");
var msg_1 = require("./msg");
var img_1 = require("./img");
var menu_1 = require("./menu");
(0, menu_1.menu)();
(0, msg_1.showMessage)('.msg--info');
(0, img_1.setPreview)();
// ユーザー登録
(0, validate_1.userValidate)(validate_1.$registerForm);
// ログイン
(0, validate_1.loginValidate)(validate_1.$loginForm);
// ユーザー編集
(0, validate_1.userValidate)(validate_1.$userEditForm);
// 記事作成
(0, validate_1.postValidate)(validate_1.$postCreateForm);
// 記事編集
(0, validate_1.postValidate)(validate_1.$postEditForm);
