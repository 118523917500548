"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showMessage = void 0;
var showMessage = function (elem) {
    var time = 4000;
    var $msgElm = document.querySelector(elem);
    if ($msgElm) {
        $msgElm.classList.add('active');
        setTimeout(function () {
            $msgElm.classList.remove('active');
        }, time);
    }
};
exports.showMessage = showMessage;
