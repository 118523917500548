"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menu = void 0;
var var_1 = require("./var");
var menu = function () {
    var $mypageName = document.getElementById('mypage__name');
    if ($mypageName !== null) {
        $mypageName.addEventListener('click', function (e) {
            e.preventDefault();
            var nextElem = this.nextElementSibling;
            if (window.innerWidth <= var_1.breakPoint) {
                nextElem.classList.toggle('active');
            }
            else {
                return false;
            }
        });
    }
};
exports.menu = menu;
