"use strict";
/**
 * Thumbnail Image
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPreview = void 0;
var setPreview = function () {
    var $preview = document.getElementById('preview');
    if (!$preview) {
        return;
    }
    var isImage = function (fileName) {
        var image = fileName.substring(fileName.lastIndexOf('.'));
        if (image.match(/\.(jpg|jpeg|png|gif|svg)$/i)) {
            return true;
        }
        return false;
    };
    var isBase64Image = function (fileName) {
        if (fileName.match(/^(data:image)?/i)) {
            return true;
        }
        return false;
    };
    var initPreview = function () {
        var $formImage = document.getElementById('form__image');
        var $formPreview = document.getElementById('form__preview');
        var $deleteBtn = document.getElementById('delete-image');
        var $hiddenImage = document.getElementById('hidden-image');
        $deleteBtn.addEventListener('click', function () {
            $preview.src = '';
            $formPreview.style.display = 'none';
            $formImage.style.display = 'block';
            $preview.classList.remove('active');
            $hiddenImage.value = '';
        });
        if (isImage($preview.src) || (isBase64Image($preview.src) && $preview.classList.contains('active'))) {
            $formPreview.style.display = 'block';
            $formImage.style.display = 'none';
        }
        else {
            $formPreview.style.display = 'none';
            $formImage.style.display = 'block';
        }
    };
    var createPreview = function (elem) {
        var fileReader = new FileReader();
        var file = elem.files[0];
        fileReader.onload = function () {
            $preview.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
        $preview.classList.add('active');
        initPreview();
    };
    if (document.querySelector('.form--post')) {
        initPreview();
        var uploadImage = document.getElementById('upload-image');
        uploadImage.addEventListener('change', function () {
            createPreview(this);
        });
    }
};
exports.setPreview = setPreview;
