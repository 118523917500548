"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postValidate = exports.loginValidate = exports.userValidate = exports.$postEditForm = exports.$postCreateForm = exports.$userEditForm = exports.$loginForm = exports.$registerForm = void 0;
/**
 * 変数
 */
// 要素
var $username = document.getElementById('name');
var $email = document.getElementById('email');
var $password = document.getElementById('password');
var $confirmPassword = document.getElementById('confirm-password');
var $postTitle = document.getElementById('title');
var $postContent = document.getElementById('content');
// フォーム
exports.$registerForm = document.getElementById('form--register');
exports.$loginForm = document.getElementById('form--login');
exports.$userEditForm = document.getElementById('form--user-edit');
exports.$postCreateForm = document.getElementById('form--post-create');
exports.$postEditForm = document.getElementById('form--post-edit');
/**
 * バリデーション関数
 */
// 必須項目判定
var isRequired = function (val) { return (val === '' ? false : true); };
// 長さ範囲判定
var isBetween = function (len, min, max) { return (len < min || len > max ? false : true); };
// メールアドレス形式判定
var isEmailValid = function (val) {
    var reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return reg.test(val);
};
// パスワード形式判定
var isPassword = function (pwd) {
    // const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var reg = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.{8,})');
    return reg.test(pwd);
};
/**
 * サクセス & エラー
 */
// サクセス
var showSuccess = function ($input) {
    $input.classList.remove('validate-error');
    $input.classList.add('validate-success');
    var $error = $input.nextElementSibling;
    $error.textContent = '';
};
// エラー
var showError = function ($input, msg) {
    $input.classList.remove('validate-success');
    $input.classList.add('validate-error');
    var $error = $input.nextElementSibling;
    $error.textContent = msg;
};
/**
 * 各項目チェック
 */
// ユーザー名チェック
var checkUsername = function () {
    var valid = false;
    var min = 1, max = 15;
    var usernameVal = $username.value.trim();
    if (!isRequired(usernameVal)) {
        showError($username, 'ユーザー名を入力してください。');
    }
    else if (!isBetween(usernameVal.length, min, max)) {
        showError($username, "\u30E6\u30FC\u30B6\u30FC\u540D\u306F".concat(min, "\u4EE5\u4E0A\u3001").concat(max, "\u4EE5\u4E0B\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"));
    }
    else {
        showSuccess($username);
        valid = true;
    }
    return valid;
};
// メールアドレスチェック
var checkEmail = function () {
    var valid = false;
    var emailVal = $email.value.trim();
    if (!isRequired(emailVal)) {
        showError($email, 'メールアドレスを入力してください。');
    }
    else if (!isEmailValid(emailVal)) {
        showError($email, 'メールアドレス形式で入力してください。');
    }
    else {
        showSuccess($email);
        valid = true;
    }
    return valid;
};
// パスワードチェック
var checkPassword = function () {
    var valid = false;
    var passwordVal = $password.value.trim();
    if (!isRequired(passwordVal)) {
        showError($password, 'パスワードを入力してください。');
    }
    else if (!isPassword(passwordVal)) {
        showError($password, 'パスワードは半角英字と半角数字をそれぞれ1文字以上使い、合計8文字以上で入力してください。');
    }
    else {
        showSuccess($password);
        valid = true;
    }
    return valid;
};
// パスワード一致確認
var checkConfirmPassword = function () {
    var valid = false;
    var confirmPasswordVal = $confirmPassword.value.trim();
    var passwordVal = $password.value.trim();
    if (!isRequired(confirmPasswordVal)) {
        showError($confirmPassword, 'パスワードを入力してください。');
    }
    else if (passwordVal !== confirmPasswordVal) {
        showError($confirmPassword, 'パスワードが一致しません。');
    }
    else {
        showSuccess($confirmPassword);
        valid = true;
    }
    return valid;
};
// 記事タイトルチェック
var checkPostTitle = function () {
    var valid = false;
    var min = 1, max = 80;
    var postTitleVal = $postTitle.value.trim();
    if (!isRequired(postTitleVal)) {
        showError($postTitle, 'タイトルを入力してください。');
    }
    else if (!isBetween(postTitleVal.length, min, max)) {
        showError($postTitle, "\u30BF\u30A4\u30C8\u30EB\u306F".concat(min, "\u4EE5\u4E0A\u3001").concat(max, "\u4EE5\u4E0B\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"));
    }
    else {
        showSuccess($postTitle);
        valid = true;
    }
    return valid;
};
// 記事タイトルチェック
var checkPostContent = function () {
    var valid = false;
    var postContentVal = $postContent.value.trim();
    if (!isRequired(postContentVal)) {
        showError($postContent, '本文を入力してください。');
    }
    else {
        showSuccess($postContent);
        valid = true;
    }
    return valid;
};
/**
 * バリデーション
 */
var userValidate = function ($form) {
    if ($form) {
        $form.addEventListener('submit', function (e) {
            var isUsernameValid = checkUsername(), isEmailValid = checkEmail(), isPasswordValid = checkPassword(), isConfirmPasswordValid = checkConfirmPassword();
            var isFormValid = isUsernameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid;
            if (isFormValid) {
                console.log('valid');
                return true;
            }
            else {
                e.preventDefault();
                console.log('invalid');
                return false;
            }
        });
    }
};
exports.userValidate = userValidate;
// ログイン
var loginValidate = function ($form) {
    if ($form) {
        $form.addEventListener('submit', function (e) {
            var isEmailValid = checkEmail(), isPasswordValid = checkPassword();
            var isFormValid = isEmailValid && isPasswordValid;
            if (isFormValid) {
                return true;
            }
            else {
                e.preventDefault();
                return false;
            }
        });
    }
};
exports.loginValidate = loginValidate;
// 記事
var postValidate = function ($form) {
    if ($form) {
        $form.addEventListener('submit', function (e) {
            var isPostTitleValid = checkPostTitle(), isPostContentValid = checkPostContent();
            var isFormValid = isPostTitleValid && isPostContentValid;
            if (isFormValid) {
                return true;
            }
            else {
                e.preventDefault();
                return false;
            }
        });
    }
};
exports.postValidate = postValidate;
